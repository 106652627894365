import { Color } from './color';

export class Book {
    id: number;
    color: { name: string, code: string };
    letter: string;
    minNumber: number;
    maxNumber: number;
    available: number[];

    constructor(color: Color, letter: string, minNumber: number, maxNumber: number) {
      this.color = color;
      this.letter = letter.toUpperCase();
      this.minNumber = minNumber;
      this.maxNumber = maxNumber;
			//this.maxAvailable = 1 + this.maxNumber - this.minNumber;
      this.available = [];
    }

		getMaxAvailable(): number {
			var n : number;
			n = 1 + this.maxNumber - this.minNumber;
			console.log("n = ", n);
			return n;
		}

    // Clear the free array, the populate with all numbers from minNumber to maxNumber
    resetFree(): void {
      console.log('resetFree, available before: ', this.available);
			//this.maxRange = 1 + this.maxNumber - this.minNumber;
			//this.setMaxAvailable();
			console.log('Max available: ', this.getMaxAvailable());
      this.available.length = 0;
      var i: number;
      for (i = this.minNumber; i <= this.maxNumber; i++) {
        this.available.push(i);
      }
			//this.maxAvailable = this.available.length;
      console.log('available after: ', this.available);
			console.log('max available: ', this.getMaxAvailable());
    }
  }