import { Component, OnInit } from '@angular/core';
import { Book } from '../book';
import { Color } from '../color';
import { Draw } from '../draw';
import { BOOKS } from '../mock-books';
import { COLORS } from '../mock-colors';
import { HashTable } from 'angular-hashtable';
import { SPELLING } from '../mock-spelling';
import { ReversePipe } from '../reversepipe';

@Component({
  selector: 'app-books',
  templateUrl: './books.component.html',
  styleUrls: ['./books.component.css']
})



export class BooksComponent implements OnInit {
  books: Book[];
  colors = COLORS;
  draws: Draw[] = [];
  draw: Draw;
  selectedBook: Book;
  spellTable: HashTable<string, any>;
  userMessage: string = '';

  onSelect(book: Book): void {
    localStorage.setItem("books", JSON.stringify(this.books));
    this.selectedBook = book;
  }

  newBook() {
    this.selectedBook = new Book({name: 'Hvit', code: 'white'}, 'A', 1, 100);
		this.selectedBook.resetFree();
    this.books.push(this.selectedBook);
    localStorage.setItem("books", JSON.stringify(this.books));
  }

  deleteBook() {
    if (confirm("Sletter loddbok, er du sikker?")) {
      var selectedIndex: number = -1;
      for (var i: number = 0; i < this.books.length; i++) {
        if ((this.books[i].color.name === this.selectedBook.color.name)
          && (this.books[i].letter === this.selectedBook.letter)) {
            selectedIndex = i;
          }
      }
      if (selectedIndex >= 0) {
        this.books.splice(selectedIndex, 1);
        localStorage.setItem("books", JSON.stringify(this.books));
        this.selectedBook = null;
      }
    }
  }

	getMaxAvailable(book: Book): number {
		if (book.maxNumber) {
			if (typeof(book.maxNumber) == 'string') {
				book.maxNumber = parseInt(book.maxNumber, 10); 
			}
		}
		if (book.minNumber) {
			if (typeof(book.minNumber) == 'string') {
				book.minNumber = parseInt(book.minNumber, 10);
			}
		}
		return 1 + book.maxNumber - book.minNumber;
	}

  compareColors(c1: Color, c2: Color): boolean {
    return c1 && c2 ? c1.name === c2.name : c1 === c2;
  }

  resetBookAvailable(book: Book): void {
      console.log('resetBookAvailable, available before: ', book.available);
      book.available.length = 0;
      var i: number;
      for (i = book.minNumber; i <= book.maxNumber; i++) {
        book.available.push(i);
      }
			//book.maxAvailable = 1 + parseInt(book.maxNumber, 10) - parseInt(book.minNumber, 10);
      console.log('resetBookAvailable, available after: ', book.available);

  }

  prepareForDrawsClicked() {
    // Initiate draw
    if (confirm("Nullstiller trekning, er du sikker?")) {
			this.draw = null;
			// Reset available array on all books
			for (let book of this.books) {
				this.resetBookAvailable(book);
			}
			// Clear the draws collection
			if (this.draws) {
				this.draws.length = 0;
			} else {
				this.draws = [];
			}
			this.userMessage = 'Trekning nullstilt. Du kan begynne å trekke på nytt.';
		}
  }

  drawClicked() {
    this.userMessage = '';
    var spelledLetter: string = '';
    var drawnBook: Book;
    var drawnNumber: number = 0;
    //console.log('Number of books:', this.books.length);
    var bookIndex: number = Math.floor((Math.random() * this.books.length) + 1);
    //console.log("Random number:", this.bookIndex);
    drawnBook = this.books[bookIndex - 1];
    var range: number = drawnBook.available.length;
    if (drawnBook.available.length === 0) {
      this.userMessage = drawnBook.color.name + ' ' + drawnBook.letter + ' har ikke flere nummer å trekke.';
      this.draw = null;
      drawnBook = null;
			//this.drawClicked(); Not sure if this actually works well
    } else {
      //console.log("Bok: " + this.drawnBook);
      //console.log("Numbers: ", this.drawnBook.minNumber, " to ", this.drawnBook.maxNumber);
      var drawnIndex: number = Math.floor(Math.random() * range);
      drawnNumber = drawnBook.available[drawnIndex];
      // Remove the drawn number to avoid duplicates
      drawnBook.available.splice(drawnIndex, 1);
      //console.log('Range', range, ', random: ', drawn);
      if (this.spellTable.has(drawnBook.letter)) { 
        spelledLetter = this.spellTable.get(drawnBook.letter).no; 
      }
      this.draw = new Draw(drawnBook.color, drawnBook.letter, drawnNumber, spelledLetter);
      var draw: Draw = this.draw;
      this.draws.push(draw);
      //console.log("Trukket nummer: " + this.drawnBook.color.name, this.drawnBook.letter, this.drawnNumber);
    }
  }

  constructor() { 
    this.draws = [];

  }

  ngOnInit() {
    this.books = JSON.parse(localStorage.getItem("books"));
    if (!this.books) {
      this.books = BOOKS;
    }
  
    this.spellTable = new HashTable<string, any>();
    for (let spell of SPELLING) {
      this.spellTable.put(spell.letter, {
        no: spell.no,
        en: spell.en
      });    
    }
  }

}
