import { Book } from './book';

export const BOOKS: Book[] = [
  new Book({name: 'Rosa', code: 'pink'}, 'A', 1, 100 ),
  new Book({ name: 'Gul', code: 'yellow'}, 'B', 1, 100),
  new Book({name: 'Hvit', code: 'white'}, 'C', 1, 100),
  new Book({ name: 'Grønn', code: 'lightgreen'}, 'D', 1, 100),
  new Book({name: 'Blå', code: 'lightblue'}, 'E', 1, 100)
  // new Book({ name: 'Hvit', code: 'white'}, 'E', 30, 32),
  // new Book({ name: 'Gul', code: 'yellow'}, 'P', 1, 4),
  // new Book({ name: 'Hvit', code: 'white'}, 'S', 1, 3),
  // new Book({ name: 'Blå', code: 'lightblue'}, 'W', 31, 34),
  // new Book({ name: 'Blå', code: ' lightblue'}, 'A', 1, 2),
  // new Book({ name: 'Grønn', code: 'lightgreen'}, 'Ø', 17, 19)
];