import { Component, OnInit } from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import { DataService } from './data.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'Trekk lodd v0.5';
  update: boolean = false;

  constructor(private swUpdate: SwUpdate) {
  }

  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe( () => {
        this.update = true;
        if (confirm("Ny versjon tilgjengelig. Hente ny version?")) {
            window.location.reload();
        }
      })
    }
  }
}
