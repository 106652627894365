import {Book} from './book';
import { Color } from './color';

export class Draw {
    color: Color;
    letter: string;
    numValue: number;
    timeStamp: Date;
    spelledLetter: string;

    constructor(color: Color, letter: string, numValue: number, spelledLetter: string ) {
       this.color = color;
       this.letter = letter;
       this.numValue = numValue;
       this.spelledLetter = spelledLetter;
       this.timeStamp = new Date();
    }
  }