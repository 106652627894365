import { Spelling } from './spelling';

export const SPELLING: Spelling[] = [
  { letter: 'A', no: 'Anna', en: 'Alfa' },
  { letter: 'B', no: 'Bernhard', en: 'Bravo' },
  { letter: 'C', no: 'Cæsar', en: 'Charlie' },
  { letter: 'D', no: 'David', en: 'Delta' },
  { letter: 'E', no: 'Edith', en: 'Echo' },
  { letter: 'F', no: 'Fredrik', en: 'Foxtrot' },
  { letter: 'G', no: 'Gustav', en: 'Golf' },
  { letter: 'H', no: 'Harald', en: 'Hotel' },
  { letter: 'I', no: 'Ivar', en: 'India' },
  { letter: 'J', no: 'Johan', en: 'Juliet' },
  { letter: 'K', no: 'Karin', en: 'Kilo' },
  { letter: 'L', no: 'Ludvig', en: 'Lima' },
  { letter: 'M', no: 'Martin', en: 'Mike' },
  { letter: 'N', no: 'Nils', en: 'November' },
  { letter: 'O', no: 'Olivia', en: 'Oscar' },
  { letter: 'P', no: 'Petter', en: 'Papa' },
  { letter: 'Q', no: 'Quintus', en: 'Quebec' },
  { letter: 'R', no: 'Rikard', en: 'Romeo' },
  { letter: 'S', no: 'Sigrid', en: 'Sierra' },
  { letter: 'T', no: 'Teodor', en: 'Tango' },
  { letter: 'U', no: 'Ulrik', en: 'Uniform' },
  { letter: 'V', no: 'Enkelt-V', en: 'Victor' },
  { letter: 'W', no: 'Dobbelt-V', en: 'Whiskey' },
  { letter: 'X', no: 'Xerxes', en: 'Xray' },
  { letter: 'Y', no: 'Yngling', en: 'Yankee' },
  { letter: 'Z', no: 'Zakarias', en: 'Zulu' },
  { letter: 'Æ', no: 'Ærlig', en: 'Ægir' },
  { letter: 'Ø', no: 'Østen', en: 'Ørnulf' },
  { letter: 'Å', no: 'Åse', en: 'Ågot' },
];